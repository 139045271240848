import React, { useState } from 'react';
import { logo } from '../../assets';
import { authProvider } from '../../utils/authProvider';
import { getUsernameLogo } from '../../utils/getUsername';

const auth = authProvider();
const username = getUsernameLogo(auth.name);

export default function Header() {

  const [isMenu, setIsMenu] = useState(false)


  const handleClick = () => {
    if (isMenu === true) {
      setIsMenu(false)
    } else {
      setIsMenu(true)
    }
  };
  const handleClose = () => {
    setIsMenu(false)
  };
  const logout = async () => {
    setIsMenu(false)
    auth.logout();
  };
  return (
    <header style={{ gridArea: 'header' }}
      className='flex w-full h-[92px] items-center py-4 pr-2 pt-5 pl-5 sticky 
top-0 z-50 bg-[#ffffff] border-[#C8DAEB] border-b'>
      <div className='top-left w-[60px] h-[48px] pb-15' data-testid='logo'>
        <img src={logo} alt='logo' />
      </div>
      <div className='text-[#00529f] text-[20px] sm:text-[25px] md:text-[32px] ml-8 font-bold drop-shadow-md md:flex-row'>
        Historical Based Entry & Review of Orders [HERO]
      </div>
      <div className="ml-auto mr-4 pl-[10px] flex-col w-20  justify-end "  tabIndex={0} onClick={handleClick} onBlur={handleClose} >
        <div className=" flex justify-center">
          <div className=" relative flex cursor-pointer h-10 w-10 items-center justify-center rounded-full bg-[#00529F] uppercase text-[#FFFFFF] font-normal"
            data-testid="profile-icon">
            {username.logo}
          </div>
        </div>
        {isMenu && (
          <div className=" absolute w-20 justify-start mt-2   text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600" id="user-dropdown"
            data-testid="logout">
            <div className="py-1 align-bottom">
              <div data-testid="logoutOptn" className=" justify-start px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={logout}>Logout</div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
};