import { useEffect, useState } from "react";
import { regExp } from "../../constants/regExp";
import InputField from "../inputField";
interface IProps {
  setInputValue?: any;
  setDataInput: (item: object) => void;
  suggestdata?: any;
  setDescInput?: any;
  value?: any;
}
const Suggestions = (props: { suggestions?: any, handleClick?: any, inputVal?: any }) => {
  let { suggestions, handleClick, inputVal } = props;
  return (
    <ul
      className="absolute z-10 mt-2 sm:w-[31%] w-[43%] origin-top-right max-h-[150px] overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-auto"
      data-testid="suggested-list"
    >
      {suggestions &&
        !!suggestions.length &&
        suggestions.map((suggestion: any) => {
          return (
            <li
              className="dropdown-item text-[9px] sm:text-[15px] py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:bg-gray-100 cursor-pointer"
              key={suggestion.cic}
              onClick={() => handleClick(suggestion)}
              data-testid="cic-list"
            >
              {suggestion.cic}
            </li>
          );
        })}
      {suggestions && !suggestions.length && inputVal.length > 2 && (
        <li className="dropdown-item text-[9px] sm:text-[15px] py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent">
          {"No Records Found"}
        </li>
      )}
    </ul>
  );
};

const AutoComplete = (props: IProps) => {
  let { suggestdata, value, setDataInput, setInputValue, setDescInput } = props;
  const [suggestions, setSuggestions] = useState<any>([]);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [input, setInput] = useState("");
  useEffect(() => {
    setInput(value);
  }, [value]);
  const handleChange = (e: any) => {
    const query = e.target.value;
    setInput(query);
    if (query.length > 2) {
      const filterSuggestion = suggestdata;
      setInputValue(e.target.value);
      setSuggestions(filterSuggestion.cicList);
      setSuggestionsActive(true);
      setDescInput("");
    } else {
      setSuggestionsActive(false);
      setDescInput("");
    }
  };
  useEffect(() => {
    setSuggestions(suggestdata.cicList);
  }, [suggestdata]);

  const handleClick = (suggestion: any) => {
    setSuggestions([]);
    setInput(suggestion.cic);
    setDescInput(suggestion.description);
    setSuggestionsActive(false);
    setDataInput(suggestion);
  };
  const handleSuggestions = (e: any) => {
    const currentTarget = e.currentTarget;
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        setSuggestionsActive(false);
        setSuggestions([]);
      }
    });
  };

  return (
    <div
      className="tag-suggestions"
      onBlur={handleSuggestions}
      tabIndex={0}
      data-testid="hidesuggestion"
    >
      <InputField
        styleClass="md:w-4/6 w-5/6 pl-4 h-[40px] bg-[#FFFFFF] border-[1px] border-[#B1B1B1] rounded "
        value={input}
        handleChange={handleChange}
        testid="autocomplete"
        inputRegex={regExp.autocompleteInput}
      />
      {suggestionsActive && <Suggestions suggestions={suggestions} handleClick={handleClick} inputVal={input}/>}
    </div>
  );
};
export default AutoComplete;
