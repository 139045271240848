import { downarrow } from '../../assets';
import { ReactNode, useEffect, useState } from 'react';
interface IProps {
    children: ReactNode | ReactNode[];
    heading?: string;
    defaultExpand?: boolean;
    expandSection?: boolean;
}
export default function Accordion(props: IProps) {
    const { children, heading, defaultExpand, expandSection } = props;
    const [open, setOpen] = useState(defaultExpand);
    useEffect(() => {
        expandSection && setOpen(true)
    }, [expandSection])
    return (
        <div
            className='accordion  mb-4 flex w-full flex-col rounded-lg bg-[#FDFDFF] 
      border-solid border-[#C8DAEB] border'
            id='accordionExample'>
            <div className={`accordion-item cursor-pointer flex items-center justify-between 
                ${open && 'border-solid border-[#C8DAEB] border-b'}`} onClick={() => setOpen(!open)} >
                <div className='flex justify-between'>
                    <button type='button'>
                        <img
                            alt='collapsible'
                            className='h-[30px] pt-1'
                            src={downarrow} />
                    </button>
                    <div className='text-md leading-6 font-nunito py-3 font-bold text-[#2B303C]'
                        data-testid='test-accordion'>
                        {heading}
                    </div>
                </div>
            </div>
            <div className={`${open ? 'block relative' : 'hidden'} py-3 px-4`} data-testid='test-accordionContent'>
                {children}
            </div>
            <div className={`${open ? 'block' : 'hidden'} h-[32px]`} data-testid='test-accordionFooter'></div>
        </div>
    );
}
