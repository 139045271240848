/* eslint-disable */

export const regExp = {
    cookieReplaceRegex: '/([.*+?\^$(){}|\[\]\/\\])/g',
    cookieRepValue: '\\$1',
    cookieMatchStartRegex: '(?:^|;\\s*)',
    cookieMatchEndRegex: '=([^;]*)',
    prstnStockRegex: /^[0-9]{0,4}$/,
    autocompleteInput : /^[0-9]*$/,
    itemGroup : /^[a-zA-Z0-9/_ -]*$/,
    restrict :/^[/_-]*$/,
};
