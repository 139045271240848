import { useState } from "react";
import { regExp } from "../../../constants/regExp";
import AutoComplete from "../../autocomplete/autocomplete";
import Button from "../../button";
import InputField from "../../inputField";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface IProps {
  inputModalData: (item: object) => void;
  inputCicValue?: any;
  inputOldCic?: any;
  suggestData?: any;
  submitCicData?: any;
}
export default function AdditemModal(props: IProps) {
  const {
    inputModalData,
    suggestData,
    submitCicData,
    inputCicValue,
    inputOldCic,
  } = props;
  const [itemmodal, setitemmodal] = useState(false);
  const [description, setDescription] = useState("");
  const [stockValue, setstockValue] = useState(0);
  const [showSlowMovingMsg, setShowSlowMovingMsg] = useState(false);
  const [showSecondCIC, setShowSecondCIC] = useState(false);
  const [cic, setCic] = useState("");
  const [oldCic, setOldCic] = useState("");
  const [modalData, setModalData] = useState<any>({});

  const storeEnv = useSelector(
    (state: RootState) => state.globalDtl && state.globalDtl.globalDtl.env
  );
  const slowMsg = Number(storeEnv?.slowMovingMsg);

  const handleModal = () => {
    setitemmodal(true);
  };
  const handleClose = () => {
    setShowSecondCIC(false);
    setitemmodal(false);
    setOldCic("");
    inputOldCic(0);
    setCic("");
    setDescription("");
    setShowSlowMovingMsg(false);
    setstockValue(0);
  };
  const setInputValue = (cicInput: any) => {
    setCic(cicInput);
    inputCicValue(cicInput);
  };
  const setOldCicValue = (cicInput: any) => {
    setOldCic(cicInput);
    inputCicValue(cicInput);
  };
  const setDescInput = (descInput: any) => {
    setDescription(descInput);
    if (!descInput && showSlowMovingMsg) {
      setShowSlowMovingMsg(false);
    }
  };
  const setDataInput = (item: any) => {
    setModalData(item);
    inputModalData(item);
    setDescription(item.description);
    if (item.itemType === slowMsg) {
      setShowSlowMovingMsg(true);
    } else {
      setShowSlowMovingMsg(false);
    }
  };
  const handleSubmit = () => {
    if (modalData?.cic && description) {
      if(modalData?.cic === oldCic) {
        toast.error("Both CIC cannot be the same")
        return
      }
      let newModalData = { ...modalData, oldCic: oldCic === "" ? 0 : oldCic };
      setitemmodal(false);
      setOldCic("");
      setCic("");
      setShowSecondCIC(false);
      setDescription("");
      setShowSlowMovingMsg(false);
      setstockValue(0);
      submitCicData({
        modalData: newModalData,
        stockValue,
        oldCic: oldCic ?? 0,
      });
      setOldCic("");
      inputOldCic(0);
    }
  };
  const handleStockChange = (e: any) => {
    setstockValue(e.target.value);
  };
  return (
    <>
      <div className="flex items-end sm:h-full sm:justify-end">
        <div>
          <button
            onClick={handleModal}
            data-testid="item-modal"
            type="button"
            className="inline-block sm:px-12 sm:py-2 py-1.5 px-10 mb-[3px] rounded font-nunito font-small bg-[#00529f] text-[#ffffff] text-[18px] leading-tight  duration-150 ease-in-out"
          >
            Add Item
          </button>
        </div>
      </div>
      <div
        id="popup-modal"
        className={` ${
          !itemmodal && "hidden"
        } flex flex-col justify-center items-center bg-[rgba(0,0,0,.2)] rounded-lg shadow overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 sm:inset-0 h-modal h-full`}
      >
        <div className="modal-content border-none shadow-lg relative flex flex-col w-[70%] sm:w-[40%] pb-5 pointer-events-auto bg-white bg-clip-padding outline-none">
          <div
            className="text-[#00529F] font-bold border-b-[#B1B1B1] border-b-[1.5px] text-center uppercase md:h-[67px] items-center align-middle pt-6"
            data-testid="pop-up"
          >
            <div className="text-[22px] text-center">Add Item</div>
          </div>
          <div className="flex flex-col justify-center pt-[35px] p-5 space-y-6">
            {showSlowMovingMsg && (
              <div
                className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 "
                role="alert"
              >
                <p className="font-bold">
                  You have selected a Slow Moving Item !
                </p>
              </div>
            )}
            <div className="flex flex-row space-y-0">
              <div>
                <div className="flex-row w-[86px] h-[37px]">
                  <p className="sub-head text-[#2C2A29] font-semibold text-[17px] py-2">
                    CIC
                  </p>
                </div>
                <AutoComplete
                  suggestdata={suggestData}
                  setInputValue={setInputValue}
                  setDataInput={setDataInput}
                  setDescInput={setDescInput}
                  value={cic}
                />
                {cic.length > 0 && (
                  <button
                    onClick={() => setShowSecondCIC(true)}
                    className="text-xs text-primary no-underline hover:underline hover:decoration-primary leading-tight"
                    // href="#"
                  >
                    <span className="leading-tight">
                      Click here to link this item to an existing CIC
                    </span>
                  </button>
                )}
              </div>
              <div className="flex flex-col self-stretch">
                {showSecondCIC && (
                  <>
                    <div className="flex-row h-[37px]">
                      <p className="sub-head text-[#2C2A29] font-semibold text-[16px] py-2">
                        Search Existing CIC
                      </p>
                    </div>
                    <AutoComplete
                      suggestdata={suggestData}
                      setInputValue={setOldCicValue}
                      setDataInput={(item: any) => {
                        setOldCic(item?.cic ?? 0);
                        inputOldCic(item?.cic);
                      }}
                      setDescInput={() => {}}
                      value={oldCic}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="space-y-0">
              <div className="flex-row">
                <p className="sub-head text-[#2C2A29] font-semibold text-[17px] py-2">
                  Presentation Stock
                </p>
              </div>
              <InputField
                maxLength={4}
                labeldisabled
                inputRegex={regExp.prstnStockRegex}
                styleClass="md:w-2/6 w-3/6 h-[37px] bg-[#FFFFFF] pl-4 border-[1px] border-[#B1B1B1] rounded left"
                handleChange={handleStockChange}
                value={stockValue}
                testid="prsnt-input"
              />
            </div>
            <div className="space-y-0">
              <div className="flex-row">
                <p className="sub-head text-[#2C2A29] font-semibold text-[17px] py-2">
                  Description
                </p>
              </div>
              <div className="w-[full] group relative">
                <InputField
                  styleClass="md:w-5/6 w-full h-[37px]  pl-4 shadow-none !bg-[#edeeef] !border-[#00000029] rounded left field cursor-not-allowed disabled input"
                  value={description}
                  testid="desc-input"
                />
                <span
                  className={`${
                    description
                      ? "absolute hidden group-hover:flex -left-2 -top-2 -translate-y-full w-48 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700"
                      : null
                  }`}
                >
                  {description}
                </span>
              </div>
            </div>
          </div>
          <div className="flex gap-4 pt-6 justify-center pl-2 pr-2">
            <Button
              text="Add"
              type="contained"
              testid="add-button"
              styleClass="w-[70%] px-[12px] py-[6px]"
              onClick={handleSubmit}
            />
            <Button
              text="Cancel"
              type="contained"
              testid="cancel-button"
              styleClass="w-[70%] px-[12px] py-[6px]"
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </>
  );
}
