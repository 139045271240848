import {
    ADD_ITEM_GROUP,
    DELETE_CIC_DETAIL,
    DELETE_ITEM_GROUP,
    GET_CIC_DETAIL,
    GET_DIVISION_ACTION,
    GET_ITEMGRP_ACTION,
    GET_ITEMGR_DETAIL,
    GET_STORES_ACTION,
    RESET_ITEMGRP,
    RESET_STORE_ITEMGRP,
    SET_CIC_DETAIL,
    SET_DIVISION_ACTION,
    SET_ITEMGRP_ACTION,
    SET_ITEMGR_DETAIL,
    SET_STORES_ACTION,
    UPDATE_ADD_CIC,
    UPDATE_CIC_DETAIL,
    UPDATE_ITEM_GROUP,
} from './types'

export const getDivisions = () => ({
    type: GET_DIVISION_ACTION
})
export const setDivision = (data: any) => ({
    type: SET_DIVISION_ACTION,
    data
})

export const getStores = (data: any) => ({
    type: GET_STORES_ACTION,
    data
})
export const setStores = (data: any) => ({
    type: SET_STORES_ACTION,
    data
})

export const getItemGrp = (data: any) => ({
    type: GET_ITEMGRP_ACTION,
    data
})
export const additemGrp = (data:any) => ({
    type : ADD_ITEM_GROUP,
    data
})

export const setItemGrp = (data: any) => ({
    type: SET_ITEMGRP_ACTION,
    data
})

export const getItemData = (data: any) => ({
    type: GET_ITEMGR_DETAIL,
    data
})
export const setItemData = (data: any) => ({
    type: SET_ITEMGR_DETAIL,
    data
})

export const deleteCic = (data: any) => ({
    type: DELETE_CIC_DETAIL,
    data
})

export const updateCicItem = (data: any) => ({
    type: UPDATE_CIC_DETAIL,
    data
})

export const getCicData = (data: any) => ({
    type: GET_CIC_DETAIL,
    data
})

export const setCic = (data: any) => ({
    type: SET_CIC_DETAIL,
    data
})

export const updateAddCic = (data: any) => ({
    type: UPDATE_ADD_CIC,
    data
})

export const updateitemGrp = (data:any) => ({
    type : UPDATE_ITEM_GROUP,
    data
})

export const deleteitemGrp = (data:any) => ({
    type : DELETE_ITEM_GROUP,
    data
})

export const resetStoreItemGrp = () => ({
    type : RESET_STORE_ITEMGRP
})


export const resetItemGrp = () => ({
    type : RESET_ITEMGRP
})