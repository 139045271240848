import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './app.css';
import AppRoutes from '../route/route';
import { authProvider } from '../../utils/authProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../loader/loader';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvVariableAction } from '../../actions/global';
import ErrorBoundary from '../error-boundary/error-boundary';
// commenting authentication invoking code until client secrets are available
const auth = authProvider();

function App() {
  const storeEnv = useSelector((state: RootState) => state.globalDtl && state.globalDtl.globalDtl.env);
  const dispatch = useDispatch();
  const pendingApi = useSelector((state: RootState) => state.globalDtl.globalDtl.pendingAPICount ? true : false);
  const [envVar, setenvVar] = useState(false);

  useEffect(() => {
    if (auth.accessToken) {
      dispatch(getEnvVariableAction());
    }
  }, [dispatch]);

  useEffect(() => {
    if (storeEnv?.apiUrl !== undefined) {
      setenvVar(true);
    }
  }, [storeEnv]);

  const displayLoader = () => {
    if(pendingApi){
      return <Loader /> ;
    } else {
      return '';
    }
  }

  return (
    <>
      {auth.accessToken ? (
        <>
          {displayLoader()}
          {envVar && (
            <>
              <Router>
                <ErrorBoundary>
                  <AppRoutes />
                </ErrorBoundary>
              </Router>
              <ToastContainer />
            </>
          )}
        </>
      ) : (
        <>{auth.clearSession()}</>
      )}
    </>
  );
}

export default App;
