import React, { useEffect, useState } from 'react';
import { editIcon, deleteIcon } from '../../assets';

interface IDropdown {
    list: Array<any>;
    label?: string;
    isMandatory?: boolean;
    onChange: (value: any) => void;
    value?: string;
    testid?: string;
    styleClass?: string;
    saveState?: boolean;
    error?: boolean;
    errorMessage?: string;
    errMsgWidth?: string;
    tooltip?: string;
    labelStyle?: string;
    editItems?: any;
    deleteItems?: any;
    showUpdateOptions?: boolean;
    optionListTestid?: string;
    showAddOptions?: boolean;
    addOptionText?: string;
    addItems?: any;
    disabled?: boolean;
    placeHolderText?: string;
    resetFields?: any;
    name?: string;
}

interface IListItem {
    id: string;
    value: string;
}

export default function CustomDropDown({
    list,
    label,
    isMandatory,
    onChange,
    testid,
    value,
    saveState,
    error,
    errorMessage,
    errMsgWidth,
    tooltip,
    labelStyle,
    editItems,
    deleteItems,
    showUpdateOptions,
    optionListTestid,
    showAddOptions,
    addOptionText,
    addItems,
    disabled,
    styleClass,
    placeHolderText,
    resetFields,
    name
}: IDropdown) {

    const [showMenu, setShowMenu] = useState(false);
    const [filterText, setfilterText] = useState('');

    useEffect(() => {
        setfilterText('')
    }, [value]);

    const editItem = (item: any) => {
        setShowMenu(!showMenu);
        editItems(item);
    }

    const deleteItem = (item: any) => {
        setShowMenu(!showMenu);
        deleteItems(item);
    }

    const logvalue = (val: any) => {
        setShowMenu(!showMenu);
        onChange(val);
        setfilterText('')
    }

    const addItem = () => {
        addItems();
    }

    const handleBlur = (e: any) => {
        const currentTarget = e.currentTarget;

        // Give browser time to focus the next element
        requestAnimationFrame(() => {
            // Check if the new focused element is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
                setShowMenu(false)
            }
        });
    }

    return (
        <div className=' items-center gap-3 w-full' >
            <label data-testid='label'
                className={`pb-1 block font-nunito ${labelStyle} text-left  f text-[#00529F]`}
            >
                {label}
                {isMandatory ? <span data-testid='required' className='text-red-600'> * </span> : null}
                {tooltip ? <img src={tooltip} alt='tooltip' className='inline'></img> : null}
            </label>

            <div data-testid={testid} className={`relative border border-solid border-[#7296B8] 
          focus:outline-none rounded ${disabled ? "shadow-none !bg-[#edeeef] border-[#00000029]" : ""}`} tabIndex={0} onClick={() => { setShowMenu(!showMenu) }} onBlur={handleBlur}>
                <div className=" text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center w-full"
                    data-testid='filtertext' >
                    <input className={`${styleClass} w-[99%] text-left outline-0 mozillaOutline`}
                        data-testid='inputfield'
                        placeholder={placeHolderText}
                        value={filterText || value}
                        onChange={(e: any) => {
                            e.preventDefault();
                            setShowMenu(true);
                            setfilterText((e.target.value));
                            if (!(e?.target.value).length) {
                                onChange(e.target.value);
                            }
                            resetFields(name);
                        }}
                        disabled={disabled}
                    />
                    <svg fill='#374151' height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={`${showMenu ? 'opacity-[1]': 'opacity-[0.4]'} css-tj5bde-Svg`}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                </div>
                <ul className={`absolute z-10 ${(showMenu && !disabled) ? 'visible' : 'invisible'}  right-0 left-0  text-gray-700 pt-1 bg-white border border-solid border-b-[#7296B8] w-full ${list?.length ? 'max-h-[170px]' : ''} overflow-y-auto`} id="menu" >
                {(showAddOptions && !filterText) &&
                        <li className="w-full grid grid-cols-6  hover:bg-gray-300" >
                            <span id="menu1" data-testid='add-item' className="rounded-t  py-2 px-3 col-span-6 cursor-pointer" onClick={addItem} >
                                {addOptionText}
                            </span>
                        </li>
                    }
                    {(list)?.filter((ele: any) => filterText.length ? (ele.value?.toLowerCase()).includes(filterText?.toLowerCase()) : true).map((item: IListItem) => (
                        <li className="w-full grid grid-cols-6  hover:bg-gray-300" key={item.id}>
                            <span id="menu1" data-testid={optionListTestid} className={`rounded-t  py-2 px-3 break-all ${showUpdateOptions ? 'col-span-4' : 'col-span-6'} cursor-pointer ${styleClass}`} onClick={() => { logvalue(item.value) }} onBlur={() => { setShowMenu(false) }}>
                                {item.value}
                            </span>
                            {showUpdateOptions &&
                                <span className="col-span-2 py-2 pr-[2px]" data-testid='edit-delete'>
                                    <div className="float-right">
                                        <img src={editIcon} className='inline pl-[5px] cursor-pointer' data-testid='edit-icn' width='25px' height='25px' alt='edit-icon' onClick={() => { editItem(item) }} />
                                        <img src={deleteIcon} onClick={() => { deleteItem(item) }} className='inline pl-[5px] cursor-pointer' data-testid='delete-icon' width='25px' height='25px' alt='add-icon' />
                                    </div></span>
                            }
                        </li>
                    ))}
                </ul>
            </div>
            {(error && !saveState) ? <span className={`pb-3 text-left text-sm text-red-400 block ${errMsgWidth}`} data-testid='label'>{errorMessage}</span> : null}
        </div>
    );
}
