
  export const checkIfDateIs28DaysOld = (yourDate: string) => {
    const currentDate = new Date();
    const parsedDate = new Date(yourDate);
    const timeDifferenceMs = currentDate.getTime() - parsedDate.getTime();
    const daysDifference = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
  
    return daysDifference <= 28
  };
  
  