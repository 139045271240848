import { all, takeLatest } from 'redux-saga/effects';
import {
    DELETE_CIC_DETAIL,
    GET_CIC_DETAIL,
    GET_DIVISION_ACTION,
    GET_ENV_VARIABLES,
    GET_ITEMGRP_ACTION,
    GET_ITEMGR_DETAIL,
    GET_STORES_ACTION,
    UPDATE_CIC_DETAIL,
    ADD_ITEM_GROUP,
    UPDATE_ADD_CIC,
    DELETE_ITEM_GROUP,
    UPDATE_ITEM_GROUP
} from '../actions/types';
import {
    addCic,
    addItemGrp,
    deleteCic,
    deleteItemGrp,
    getCicItem,
    getDivisionSaga,
    getItemCICGrp,
    getItemGrp,
    getStores,
    updateCic,
    updateItemGrp
} from './admin';
import {
    getEnvVar
} from './global';

export function* rootSaga() {
    yield all([
        takeLatest(GET_ENV_VARIABLES, getEnvVar),
        takeLatest(GET_DIVISION_ACTION, getDivisionSaga),
        takeLatest(GET_STORES_ACTION, getStores),
        takeLatest(GET_ITEMGRP_ACTION, getItemGrp),
        takeLatest(GET_ITEMGR_DETAIL, getItemCICGrp),
        takeLatest(DELETE_CIC_DETAIL, deleteCic),
        takeLatest(UPDATE_CIC_DETAIL, updateCic),
        takeLatest(GET_CIC_DETAIL, getCicItem),
        takeLatest(UPDATE_ADD_CIC, addCic),
        takeLatest(ADD_ITEM_GROUP,addItemGrp),
        takeLatest(DELETE_ITEM_GROUP,deleteItemGrp),
        takeLatest(UPDATE_ITEM_GROUP,updateItemGrp)
    ]);
}
