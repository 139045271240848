import Accordion from "../accordian/accordian";
interface IProps {
    errorUpdate?: any;
}
const ErrorInfo = (props: IProps) => {
    let { errorUpdate } = props;
    return (
        <>
        <div className= 'w-full h-screen bg-[#F6F6F6] overflow-hidden flex flex-col'>
            <div className='font-semibold ml-6 pb-4 pt-4 text-[25px]'
            data-testid='error-info'>Something Went Wrong</div>
            <div className='block ml-5' id='errorInfo'>
                <Accordion heading='Error Info' defaultExpand>
                    <div>
                        <p>{errorUpdate}</p>
                    </div>
                </Accordion>
            </div>
            </div>
        </>
    )
}
export default ErrorInfo;
