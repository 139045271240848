import { call, put } from "@redux-saga/core/effects";
import { setCic, setDivision, setItemData, setItemGrp, setStores } from "../actions/admin";
import adminEndpoints from '../end-points/admin'
import { toast } from "react-toastify";
import { responseMsg } from "../constants/responseMsg";
import store from "../store";
import { allstoresValue } from "../constants/constantValues";

export function* getDivisionSaga() {
    const { response, err } = yield call(adminEndpoints.getDivisionAPI)

    if (response) {
        yield put(setDivision(
            response
        ));
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.fetchDivisionFailure);
        yield put(setDivision(
            {}
        ));
    }
}

export function* getStores(action: any) {
    const { response, err } = yield call(adminEndpoints.getStoreAPI, action.data)
    if (response) {
        let storeDta = JSON.parse(JSON.stringify(response));
        storeDta?.storeList?.unshift(allstoresValue);
        yield put(setStores(
            storeDta
        ));

        //clear itemgrp on changing divison (store load)
        // yield put(setItemGrp(
        //     {}
        // ));
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.fetchStoresFailure);
        yield put(setStores(
            {}
        ));
    }
}

export function* getItemGrp(action: any) {
    const { response, err } = yield call(adminEndpoints.getItemGrpAPI, action.data)
    if (response) {
        yield put(setItemGrp(
            response
        ));
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.fetchItemGrpFailure);
        yield put(setItemGrp(
            {}
        ));
    }
}

export function* addItemGrp(action: any) {
    const { addResponse, err } = yield call(adminEndpoints.addItemGrpAPI, action.data)
    if (addResponse) {
        const { response } = yield call(adminEndpoints.getItemGrpAPI, action.data)
        if (response) {
            yield put(setItemGrp(
                response
            ));
        }
        toast.success(addResponse.statusMessage)
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.addItemGrpFailure)
    }
}

export function* getItemCICGrp(action: any) {
    // clear results from redux state before fetching again to prevent showing old results
    yield put(setItemData(
        {}
    ));
    const { response, err } = yield call(adminEndpoints.getItemCICAPI, action.data);
    if (response) {
        yield put(setItemData(
            response
        ));
    } else if (err) {
        toast.error(err['message'] || err['error'] || responseMsg.fetchCicItemsFailure);
        yield put(setItemData(
            {}
        ));
    }
}

export function* deleteCic(action: any) {
    let { deleteStagedItem, searchQry } = action.data;
    let data = {
        divId: searchQry.divId,
        storeId: searchQry.storeId,
        cic: deleteStagedItem.cic,
        groupId: searchQry.itemGroupId
    }
    const { responseDelete, err } = yield call(adminEndpoints.deleteCicAPI, data);
    if (responseDelete) {
        const { response } = yield call(adminEndpoints.getItemCICAPI, searchQry);
        if (response) {
            yield put(setItemData(
                response
            ));
        }
        toast.success(responseDelete.statusMessage);
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.deleteCicFailure);
    }
}

export function* updateCic(action: any) {
    let { cicSaveData, searchQry, isModifyStores } = action.data;
    const { responseUpdate, err } = yield call(adminEndpoints.updateCicAPI, {...cicSaveData, isModifyStores});
    if (responseUpdate) {
        const { response } = yield call(adminEndpoints.getItemCICAPI, searchQry);
        if (response) {
            yield put(setItemData(
                response
            ));
        }
        toast.success(responseUpdate.statusMessage);
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.updateCicFailure);
    }
}

export function* getCicItem(action: any) {
    yield put(setCic(
        {}
    ));
    const { response, err } = yield call(adminEndpoints.getCicAPI, action.data)
    if (response) {
        yield put(setCic(
            response
        ));
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.fetchCicSuggestionFailure);
        yield put(setCic(
            {}
        ));
    }
}

export function* addCic(action: any) {
    let { searchCic, searchQry, presentationStock, oldCic } = action.data;
    let addReqstBody = { presentationStock, ...searchCic,oldCic };
    const { addCicResponse, err } = yield call(adminEndpoints.addCicApi, addReqstBody)
    if (addCicResponse) {
        const { response } = yield call(adminEndpoints.getItemCICAPI, searchQry);

        //clear suggestions data from redux state after adding
        yield put(setCic(
            {}
        ));
        if (response) {
            yield put(setItemData(
                response
            ));
            toast.success(addCicResponse.statusMessage);
        }
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.addCicFailure);
    }
}

export function* deleteItemGrp(action: any) {
    const itemCicdta: any = store?.getState()?.adminScreen?.itemCicData;
    const { deleteResponse, err } = yield call(adminEndpoints.deleteItemGrpAPI, action.data)
    if (deleteResponse) {
        const { response } = yield call(adminEndpoints.getItemGrpAPI, action.data)
        if (response) {
            yield put(setItemGrp(
                response
            ));
        }
        if (itemCicdta?.groupId && (itemCicdta.groupId === action.data.groupId)) {
            yield put(setItemData(
                {}
            ));
        }
        toast.success(deleteResponse.statusMessage);
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.deletItemGrpFailure);
    }
}

export function* updateItemGrp(action: any) {
    const { updateResponse, err } = yield call(adminEndpoints.updateItemGrpAPI, action.data)
    if (updateResponse) {
        const { response } = yield call(adminEndpoints.getItemGrpAPI, action.data)
        if (response) {
            yield put(setItemGrp(
                response
            ));
        }
        toast.success(updateResponse.statusMessage);
    } else {
        toast.error(err['message'] || err['error'] || responseMsg.updateItemGrpFailure);
    }
}
