/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

interface IButton {
  type?: string;
  text: string;
  onClick?: () => void;
  isLoading?: boolean;
  [x: string]: any;
  testid?: string;
  styleClass?: string;
}

export default function Button({
  type,
  text,
  onClick,
  isLoading = false,
  testid,
  styleClass,
  ...props
}: IButton) {
  const disabledClass =  props?.disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-600';
  return type === 'contained' ? (
    <button
      type="button"
      onClick={onClick}
      data-testid={testid}
      className={` ${styleClass} font-sm rounded-md bg-primary text-base text-white transition-all  focus:outline-none focus:ring-4 focus:ring-blue-300 ${disabledClass}`}
      {...props}
    >
      {text}
    </button>
  ) : (
    <button
      type="button"
      onClick={onClick}
      data-testid={testid}
      className={`font-sm h-9 rounded-md border border-primary bg-white px-6 text-base text-primary transition-all  focus:outline-none focus:ring-4 focus:ring-gray-200 ${disabledClass}`}
      {...props}
    >
      {text}
    </button>
  );
}
