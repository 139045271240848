import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Button from "../../../components/button";
import CustomDropDown from "../../../components/customDropDown/customDropDown";
import Header from "../../../components/header/header";
import AddGroupModal from "../../../components/modal/AddItemGroupModal/addItemGrpModal";
import AdditemModal from "../../../components/modal/AdditemModal/additemModal";
import ConfirmModal from "../../../components/modal/confirmModal";
import EditItemGroupModal from "../../../components/modal/editItemGrpModal/editItemGrpModal";
import { allstoresValue } from "../../../constants/constantValues";
import { authProvider } from "../../../utils/authProvider";
import AdminRecords from "../adminRecords/adminRecords";
import ToggleSwitch from "../../../components/switch/toggleSwitch";

const auth = authProvider();

interface IProps {
  handleChange?: any;
  getDivision: () => void;
  divisionDropdown?: any;
  storeDropdown?: any;
  getStores: (data: any) => void;
  getItemGrp: (data: any) => void;
  getItemData: (data: any) => void;
  itenGroupDropdown?: any;
  itemCicData?: any;
  deleteCic: (item: object) => void;
  updateCicdata: (item: object) => void;
  getCicData: (data: any) => void;
  addSuggestionData?: any;
  updateAddCic: (item: object) => void;
  deleteitemGrp: (item: object) => void;
  updateitemGrp: (item: object) => void;
  resetStoreItemGrp?: any;
  resetItemGrp?: any;
}

const MoreSelectedBadge = (selectedprops: any) => {
  let { items } = selectedprops;
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Nunito Sans",
    fontSize: "12px",
    padding: "3px",
    order: 99
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `${length} store${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = (multiprops: any) => {
  let { index, getValue, ...props } = multiprops;
  const maxToShow = 0;

  const overflow = getValue()
    .filter((item: any) => item.value !== allstoresValue)
    .map((x: any) => x.label);

  const moreJsx = index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;

  return index < maxToShow ? (
    <components.MultiValue  {...props} />
  ) : moreJsx;
};
const Option = (innerProps: any) => {
  let { isFocused, isSelected, getStyles } = innerProps;

  const propsVal = {
    ...innerProps,
  };

  return (
    <div>
      <components.Option {...propsVal}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        className={isSelected ? '!bg-transparent hover:bg-gray-300 !text-[#000]' : "!bg-transparent"}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
        />{" "}
        <label>{propsVal.label}</label>
      </components.Option>
    </div>
  );
};

export const AdminLayoutComp = ({
  getDivision,
  divisionDropdown,
  storeDropdown,
  itenGroupDropdown,
  itemCicData,
  getStores,
  getItemGrp,
  getItemData,
  deleteCic,
  updateCicdata,
  getCicData,
  addSuggestionData,
  updateAddCic,
  deleteitemGrp,
  updateitemGrp,
  resetStoreItemGrp,
  resetItemGrp }: IProps) => {

  let [division, setDivision] = useState('');
  let [store, setStore] = useState<any>([]);
  let [itemGrp, setitemGrp] = useState('');
  let [searchQry, setSearchQry] = useState<any>({});
  let [cicSaveData, setcicSaveData] = useState<any>({});
  let [deleteConfPopUp, setDeleteConfPopUp] = useState(false);
  let [deleteStagedItem, setdeleteStagedItem] = useState({});
  let [searchCic, setSearchCic] = useState({});
  let [oldCic, setOldCic] = useState(0);
  let [itemGroup, setItemGroup] = useState<any>({});

  let [showaddGrpModal, setShowaddGrpModal] = useState(false);
  let [showaddItem, setShowaddItem] = useState(false);
  let [deleteItemGrpConfPopUp, setDeleteItemGrpConfPopUp] = useState(false);
  let [editItemGrpConfPopUp, setEditItemGrpConfPopUp] = useState(false);

  let [deleteStagItemGrp, setdeleteStagItemGrp] = useState<any>({});
  let [updateStagItemGrp, setupdateStagItemGrp] = useState<any>({});
  let [editedItemGrp, seteditedItemGrp] = useState<any>({});
  let [showaddItemGrp, setShowaddItemGrp] = useState(false);
  let [storeSelected, setStoreSelect] = useState(false);
  const [isModifyStores, setIsModifyStores] = useState(false)
  const [disableStores, setDisableStores] = useState(false)

  useEffect(() => {
    if (itemGrp === updateStagItemGrp.value) {
      setitemGrp(editedItemGrp);
    } else if (itemGrp === deleteStagItemGrp.value) {
      setitemGrp("");
      setShowaddItem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itenGroupDropdown]);

  useEffect(() => {
    let { division, store } = auth;

    if (division && !store) {
      getStores(division);
      getItemGrp({
        divId: division,
        storeId: [],
      });
      setDivision(division);

    } else if (!division && store) {
      getDivision();
    } else if (!division) {
      getDivision();
    } else if (division && store) {
      setDivision(division);
      setStore(store);
      getItemGrp({
        divId: division,
        storeId: store,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const divisionChange = (value: string) => {
    setDivision(value);
    if ((!auth.division && auth.store) || !auth.store) setStore([]);
    setitemGrp('');
    setShowaddItem(false);
    setShowaddItemGrp(true);

    const divItem = (divisionDropdown?.divisionList).find(
      (item: any) => item.divisionName.trim() === value.toUpperCase()
    );
    if ((!auth.store || (!auth.division && auth.store)) && divItem)
     { 
      getStores(divItem.divisionNumber)
      // isModifyStores && getItemGrp({
      //   divId: divItem.divisionNumber,
      //   storeId: [],
      // });
     getItemGrp({
        divId: divItem.divisionNumber,
        storeId: [],
      });
    }

    if (auth.store && auth.division && divItem) {
      getItemGrp({
        divId: divItem.divisionNumber,
        storeId: auth.store,
      });
      setShowaddItemGrp(true);

    }
    isModifyStores && setDisableStores(true)
  };

  const triggerItemGrpfetch = (newComponentState: any) => {

    const divItem = !auth.division
      ? (divisionDropdown?.divisionList).find(
        (item: any) => item.divisionName.trim() === division.toUpperCase()
      )
      : "";
    if ((auth.division || divItem) && newComponentState) {
      setShowaddItemGrp(true);
      getItemGrp({
        divId: auth.division || divItem.divisionNumber,
        storeId: newComponentState.map((item: any) => item.value)
      })
      setSearchQry({
        ...searchQry,
        storeId: store?.length > 0 ? newComponentState.map((item: any) => item.value) : [],
      })

    }
  }

  const storeChange = (value: any, actionMeta?: any) => {
    const { action, option, removedValue } = actionMeta;
    const selectAllIsSelected = !!value.find(
      (o: any) => o.value === allstoresValue,
    );
    let newComponentState = [];
    if (
      (action === "deselect-option" &&
        option.value === allstoresValue) ||
      (action === "remove-value" &&
        removedValue.value === allstoresValue)
    ) {
      newComponentState = [];
    } else if (
      actionMeta.action === "deselect-option" &&
      selectAllIsSelected
    ) {
      newComponentState = store.filter((ele: any) => ((ele.value !== option.value) && (ele.value !== allstoresValue)));
    } else if ((action === "select-option" && option.value === allstoresValue) ||
      (value.length === (storeDropdown?.storeList.length - 1))) {
      newComponentState = (storeDropdown?.storeList?.map((item: string) => ({
        value: item,
        label: (item === allstoresValue) ? 'Select All Stores' : item
      })));
    } 
    else if(action == "clear"){
     setitemGrp('');
     resetItemGrp();
     setStore([])
    //  setDisableStores(false)
    //  setIsModifyStores(false)
     setStoreSelect(false)
    }
    else {
      newComponentState = value;
      setStoreSelect(action == "select-option" || value?.length > 0 ? true : false)
      
    }
    setStore(newComponentState);
    setSearchQry({...searchQry,
      storeId: newComponentState?.length > 0 ? newComponentState.map((item: any) => item.value) : [],
    })
   if(!isModifyStores) {
    setitemGrp('');
    resetItemGrp();
   }
    triggerItemGrpfetch( newComponentState ); 
  };



  const itmGrpChange = (value: string) => {
    setitemGrp(value);
    const divItem = !auth.division
      ? (divisionDropdown?.divisionList).find(
        (item: any) => item.divisionName.trim() === division.toUpperCase()
      )
      : "";
    const itemGrpItem = (itenGroupDropdown?.itemGroupList).find(
      (item: any) => item.groupName === value
    );
    setShowaddItemGrp(true);
    if ((divItem || auth.division) && itemGrpItem) {
      setShowaddItem(true);
      let isNewGrp = false;
      if(storeSelected == false && itemGrp && itemGrp !== value ){
        isNewGrp = true
      }
      getItemData({
        divId: (auth.division || divItem.divisionNumber),
        storeId: isNewGrp && !storeSelected ? [] :  store?.length > 0 ? store.map((item: any) => item.value) : [],
        itemGroupId: itemGrpItem.groupId
      })
      setItemGroup(itemGrpItem.groupId)
      setDisableStores(false)
      setSearchQry({
        divId: (auth.division || divItem.divisionNumber),
        storeId: isNewGrp ? [] : store?.length > 0 ? store.map((item: any) => item.value) : [],
        itemGroupId: itemGrpItem.groupId
      })
    }
  };



  useEffect(() => {
    // let data = [];
    // data = (store.map((item: string) => ({
    //   value: item,
    //   label:  (item === allstoresValue) ? 'Select All Stores' : item
    // })));
    // setStore(data)
      // !storeSelected && itemCicData?.storeId?.length > 0  ? setStore(data) : setStore(store) 

    setSearchQry({...searchQry,
      storeId: store?.length > 0 ? store.map((item: any) => item.value) : [],
    })
    
  }, [store])


  useEffect(() => {
    let data = [];
    data = (itemCicData?.storeId?.map((item: string) => ({
      value: item,
      label:  (item === allstoresValue) ? 'Select All Stores' : item
    })));
    // isModifyStores && setStore(data)
   setStore(data)
      // !storeSelected && itemCicData?.storeId?.length > 0  ? setStore(data) : setStore(store) 
   setSearchQry({...searchQry,
      storeId: store?.length > 0 ? store.map((item: any) => item.value) : [],
    })
    
  }, [itemCicData?.storeId] )

  
  
  const hideAddGrpModal = () => {
    setShowaddGrpModal(false);
  };
  const deleteCicItem = (item: object) => {
    setDeleteConfPopUp(true);
    setdeleteStagedItem(item);
  };

  const saveCic = (item: object) => {
    setcicSaveData(item);
    setStoreSelect(false)
  };

  const updateCic = () => {
    let cicArgData = JSON.parse(JSON.stringify(cicSaveData));
    // cicArgData.storeId =  store?.length > 0 ? store.map((item: any) => item.value) : cicArgData.storeId;
    setcicSaveData(cicArgData)
    updateCicdata({cicSaveData, searchQry, isModifyStores: isModifyStores });
  };

  const cicChange = (cicValue: any) => {
    const divItem = !auth.division
      ? (divisionDropdown?.divisionList).find(
        (item: any) => item.divisionName.trim() === division.toUpperCase()
      )
      : "";
    if (auth.division || auth.store || divItem) {
      getCicData({
        divId: auth.division || divItem.divisionNumber,
        storeId: store.map((item: any) => item.value),
        groupId: itemGroup,
        cicId: cicValue,
      });
    }
  };

  const toggleDeletePopUp = () => {
    setDeleteConfPopUp(false);
  };

  const toggleDeleteItmeGrpPopUp = () => {
    setDeleteItemGrpConfPopUp(false);
  };

  const toggleEditItmeGrpPopUp = () => {
    setEditItemGrpConfPopUp(false);
  };

  const deleteItem = () => {
    setDeleteConfPopUp(false);
    deleteCic({ deleteStagedItem, searchQry });
  };
  const createCic = (cicData: object) => {
    const divItem = !auth.division
      ? (divisionDropdown?.divisionList).find(
        (item: any) => item.divisionName.trim() === division.toUpperCase()
      )
      : "";
    if (auth.division || auth.store || divItem) {
      setSearchCic({
        divId: auth.division || divItem.divisionNumber,
        storeId: store.map((item: any) => item.value),
        groupId: itemGroup,
        ...cicData,
      }); 
    }
  };
  const createOldCic = (oldCicData: any) => {
    setOldCic(oldCicData);
  };

  const addCic = (cicData: any) => {
    if (cicData?.stockValue === "") {
      updateAddCic({
        searchCic,
        searchQry,
        presentationStock: parseInt("0", 10),
        oldCic,
      });
    } else {
      updateAddCic({
        searchCic,
        searchQry,
        presentationStock: parseInt(cicData?.stockValue, 10),
        oldCic,
      });
    }
  };

  const deleteItemGrp = () => {
    setDeleteItemGrpConfPopUp(false);
    const divItem = !auth.division
      ? (divisionDropdown?.divisionList).find(
        (item: any) => item.divisionName.trim() === division.toUpperCase()
      )
      : "";
    if (auth.division || auth.store || divItem) {
      deleteitemGrp({
        divId: auth.division || divItem.divisionNumber,
        storeId: store.map((item: any) => item.value),
        groupId: deleteStagItemGrp.id
      });
    }
  };

  const deleteItemGrpPop = (item: any) => {
    setdeleteStagItemGrp(item);
    setDeleteItemGrpConfPopUp(true);
  };

  const editItemGrpPop = (item: any) => {
    setupdateStagItemGrp(item);
    setEditItemGrpConfPopUp(true);
  };

  const updateItemGrp = (itemGrp: any) => {
    seteditedItemGrp(itemGrp.editedGrpName);
    setEditItemGrpConfPopUp(false);
    const divItem = !auth.division
      ? (divisionDropdown?.divisionList).find(
        (item: any) => item.divisionName.trim() === division.toUpperCase()
      )
      : "";
    if (auth.division || auth.store || divItem) {
      updateitemGrp({
        divId: auth.division || divItem.divisionNumber,
        storeId: store.map((item: any) => item.value),
        itemGroupName: itemGrp.editedGrpName,
        groupId: itemGrp.editedGrpId,
      });
    }
  };

  const addItems = () => {
    setShowaddGrpModal(true);
  };

  const resetFields = (name: string) => {
    if (name === "division") {
      resetStoreItemGrp();
      setStore('');
      setitemGrp('');
      
    }
    setShowaddItem(false);
  };

  const toggleStores = () => {
    resetFields("division")
    setStore([])
    setDivision("")
    setDisableStores(isModifyStores ? false : true)
    setIsModifyStores(prev => !prev)
  }

  return (
    <>
      <div className="bg-[#F6F6F6] h-screen ">
        <Header />
        <div
          className="block w-full pb-5 pt-[10px] px-16  bg-[#F6F6F6]   flex flex-col "
          id="contentPart"
        >
          <div className="flex flex-col bg-[#FFFFFF] rounded shadow-md justify-center py-[10px] px-5">
            <div className="grid grid-cols-12  mb-[10px]  w-full">
              <div className="col-span-12 sm:col-span-3 w-full sm:w-[80%] p-5 sm:p-0">
                {auth.division ? (
                  <CustomDropDown
                    label="Division"
                    styleClass="capitalize"
                    list={divisionDropdown?.divisionList?.map(
                      (item: { divisionNumber: any; divisionName: any }) => ({
                        id: String(item.divisionNumber),
                        value: item.divisionName.trim().toLowerCase(),
                      })
                    )}
                    labelStyle="font-bold text-[20px]"
                    onChange={divisionChange}
                    value={auth.division}
                    disabled
                    testid="division"
                    optionListTestid="divisionOptions"
                  />
                ) : (
                  <CustomDropDown
                    label="Division"
                    styleClass="capitalize"
                    list={divisionDropdown?.divisionList?.map(
                      (item: { divisionNumber: any; divisionName: any }) => ({
                        id: String(item.divisionNumber),
                        value: item.divisionName.trim().toLowerCase(),
                      })
                    )}
                    labelStyle="font-bold text-[20px]"
                    onChange={divisionChange}
                    value={division}
                    testid="division"
                    optionListTestid="divisionOptions"
                    placeHolderText="Select Division"
                    name="division"
                    resetFields={resetFields}
                  />
                )}
              </div>


              <div className="col-span-12 sm:col-span-3 w-full sm:w-[80%] p-5 sm:p-0">
                {auth.division && auth.store ? (
                  <CustomDropDown
                    label="Store"
                    list={storeDropdown?.storeList?.map((item: string) => ({
                      id: item,
                      value: item === allstoresValue ? "Select All Stores" : item,
                    }))}
                    labelStyle="font-bold text-[20px]"
                    onChange={storeChange}
                    value={auth.store}
                    testid="store"
                    optionListTestid="storeOptions"
                    disabled
                  />) :
                  <>
                    <p className="sub-head text-[#00529F] font-bold text-[20px]  pt-1">
                      Store
                    </p>
                    <Select
                      options={storeDropdown?.storeList?.map((item: string) => ({
                        value: item,
                        label: (item === allstoresValue) ? 'Select All Stores' : item
                      }))}
                      placeholder="Select Store"
                      classNames={{
                        container: () =>
                          "border-[#7296B8] border-[1px] rounded text-[#00529F]  hover:shadow-none active:shadow-none",
                        control: () => "h-full !border-[transparent] border-0 !shadow-none overflow-x-hidden",
                        placeholder: () => "flex items-center whitespace-nowrap text-gray-700 font-semibold opacity-[0.7]",
                        option: () => "text-[#2C2A29] !cursor-pointer  hover:!bg-gray-300",
                        menu: () => "text-[#2C2A29] cursor-pointer !z-10",
                        input: () => " h-full  text-[#00529F] border-[transparent] text-gray-700 font-semibold outline-0 mozillaOutline",
                        indicatorsContainer: () => "opacity-100 text-[#374151]"
                      }}
                      onChange={storeChange}
                      value={store === undefined ? [] : store}
                      isMulti
                      noOptionsMessage={() => null}
                      isClearable={true}
                      components={{
                        Option: Option,
                        IndicatorSeparator: () => null,
                        MultiValue
                      }}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      isDisabled={disableStores}
                    />
                  </>
                }
              </div>


              <div className="col-span-12 sm:col-span-3 w-full sm:w-[80%] p-5 sm:p-0">
                <CustomDropDown
                  label="Item Group"
                  list={itenGroupDropdown?.itemGroupList?.map(
                    (item: { groupId: any; groupName: any }) => ({
                      id: item.groupId,
                      value: item.groupName,
                    })
                  )}
                  labelStyle="font-bold text-[20px]"
                  onChange={itmGrpChange}
                  value={itemGrp}
                  testid="group"
                  optionListTestid="itemGrpOptions"
                  showUpdateOptions
                  deleteItems={deleteItemGrpPop}
                  editItems={editItemGrpPop}
                  showAddOptions={
                    division &&
                      // store &&
                      (showaddItemGrp || (auth.store && auth.division))
                      ? true
                      : false
                  }
                  addOptionText="Add New Item Group"
                  addItems={addItems}
                  placeHolderText="Select Item Group"
                  name="itemgrp"
                  resetFields={resetFields}
                />
              </div>
              <div className="flex flex-row items-center col-span-12 sm:col-span-3 md:col-span-3 gap-2 itemcol-span-12  w-full sm:w-[100%] p-5 sm:p-0">
              <ToggleSwitch
                  id="stores"
                  label={`Modify: ${isModifyStores ? "Stores" : "Items"}`}
                  checked={isModifyStores}  
                  onChange={toggleStores}
                  className="mx-2"
              />
                {showaddItem && !isModifyStores && (
                  <AdditemModal
                    suggestData={addSuggestionData}
                    inputCicValue={cicChange}
                    inputOldCic={createOldCic}
                    inputModalData={createCic}
                    submitCicData={addCic}
                  />
                )}
              </div>
            </div>
          </div>

          {showaddGrpModal && (
            <AddGroupModal
              isModalOpen={showaddGrpModal}
              hideAddGroupModal={hideAddGrpModal}
              division={division}
              store={store}
            />
          )}
          <ConfirmModal
            title="Confirmation"
            message="Do you want to delete this item ?"
            isModalOpen={deleteConfPopUp}
            onClose={toggleDeletePopUp}
            onSuccess={deleteItem}
          />
          <ConfirmModal
            title="Confirmation"
            message="Do you want to delete this item group?"
            isModalOpen={deleteItemGrpConfPopUp}
            onClose={toggleDeleteItmeGrpPopUp}
            onSuccess={deleteItemGrp}
          />
          <EditItemGroupModal
            isModalOpen={editItemGrpConfPopUp}
            hideAddGroupModal={toggleEditItmeGrpPopUp}
            updateItmGroupFn={updateItemGrp}
            itemgrpDta={updateStagItemGrp}
            itenGroupDropdown={itenGroupDropdown}
          />
          {division &&
            store &&
            itemGrp &&
            itemCicData?.products &&
            itemCicData?.products.length > 0 && (
              <div className="flex flex-col bg-[#FFFFFF] rounded shadow-md px-10 pb-10 pt-5 mt-[10px] sm:px-20">
                <AdminRecords
                  cicData={itemCicData}
                  deleteCicItem={deleteCicItem}
                  saveCicItem={saveCic}
                  store={store}
                  isModifyStores={isModifyStores}
                />
                {itemCicData?.products && itemCicData?.products.length && (
                  <div className="flex justify-end mt-5 h-1/5">
                    <Button
                      text="SAVE"
                      type="contained"
                      testid="updateCicBtn"
                      styleClass="w-[240px] h-[48px]"
                      onClick={updateCic}
                    ></Button>{" "}
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </>
  );
};
