import { AnyAction } from "redux";
import {
    RESET_ITEMGRP,
    RESET_STORE_ITEMGRP,
    SET_CIC_DETAIL,
    SET_DIVISION_ACTION,
    SET_ITEMGRP_ACTION,
    SET_ITEMGR_DETAIL,
    SET_STORES_ACTION
} from "../actions/types";
import { IReduxAdmin } from '../store'


const AdminScreenReducer = (state: IReduxAdmin = {
    divisionDropdown: {}, storeDropdown: {}, itenGroupDropdown: {}, itemCicData: {}, addSuggestionData: {}
}, action: AnyAction= {type: ''}): IReduxAdmin => {
    switch (action.type) {
        case SET_DIVISION_ACTION:
            return {
                ...state,
                divisionDropdown: {
                    ...action.data
                }
            }
        case SET_STORES_ACTION:
            return {
                ...state,
                storeDropdown: {
                    ...action.data
                }
            }
        case SET_ITEMGRP_ACTION:
            return {
                ...state,
                itenGroupDropdown: {
                    ...action.data
                }
            }
        case SET_ITEMGR_DETAIL:
            return {
                ...state,
                itemCicData: {
                    ...action.data
                }
            }
        case SET_CIC_DETAIL:
            return {
                ...state,
                addSuggestionData: {
                    ...action.data
                }
            }
        case RESET_STORE_ITEMGRP:
            return {
                ...state,
                storeDropdown: {}, itenGroupDropdown: {}
            }
        case RESET_ITEMGRP:
            return {
                ...state,
                itenGroupDropdown: {}
            }
    }
    return state
}


export default AdminScreenReducer;