import { call, put } from 'redux-saga/effects';
import envVariable from '../end-points/env-param';
import { setEnvVariable, setEnvVariableFailure } from '../actions/global';

export function* getEnvVar() {
    const { response } = yield call(envVariable.getEnvVariableApi);
    if (response) {
        yield put(setEnvVariable(
            response
        ));
    } else {
        yield put(setEnvVariableFailure());
    }
}