import React, { ChangeEvent } from 'react';

interface ToggleSwitchProps {
    id: string;
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    className?: string;
    }

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  label,
  checked,
  onChange,
  disabled = false,
  className = ''
}) => {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
      };

  return (
    <div className="flex flex-col items-center gap-2">
      {label && (
        <div className={`
          font-bold text-[14px] w-24 text-center
          ${disabled ? 'text-gray-400' : 'text-[#00529f]'}
        `}>
          {label}
        </div>
      )}
      <div className={`relative ${className}`}>
        <input
          id={id}
          type="checkbox"
          className="sr-only"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <div
          className={`
            block 
            w-16 
            h-8 
            rounded-full 
            ${checked ? 'bg-[#00529f]' : 'bg-gray-300'}
            ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
            transition-colors duration-300
          `}
          onClick={() => !disabled && onChange(!checked)}
        />
        <div
          onClick={() => !disabled && onChange(!checked)}

          className={`
            absolute 
            left-1 
            top-1 
            bg-white 
            w-6 
            h-6 
            rounded-full 
            shadow-md
            transform
            transition-transform duration-300
            cursor-pointer
            ${checked ? 'translate-x-8' : 'translate-x-0'}
          `}
        />
      </div>
      
    </div>
  );
};

export default ToggleSwitch