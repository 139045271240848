export const GET_LOGIN_STATUS = 'GET_LOGIN_STATUS';
export const GET_LOGIN_STATUS_SUCCESS_ACTION = 'GET_LOGIN_STATUS_SUCCESS_ACTION';
export const GET_LOGIN_STATUS_FAILURE_ACTION = 'GET_LOGIN_STATUS_FAILURE_ACTION';
export const SET_DIVISION_ACTION = 'SET_DIVISION_ACTION';
export const GET_DIVISION_ACTION = 'GET_DIVISION_ACTION';
export const SET_STORES_ACTION = 'SET_STORES_ACTION';
export const GET_STORES_ACTION = 'GET_STORES_ACTION';
export const SET_ITEMGRP_ACTION = 'SET_ITEMGRP_ACTION';
export const GET_ITEMGRP_ACTION = 'GET_ITEMGRP_ACTION';
export const SET_ITEMGR_DETAIL = 'SET_ITEMGR_DETAIL';
export const GET_ITEMGR_DETAIL = 'GET_ITEMGR_DETAIL';
export const DELETE_CIC_DETAIL = 'DELETE_CIC_DETAIL';
export const UPDATE_CIC_DETAIL = 'UPDATE_CIC_DETAIL';
export const GET_CIC_DETAIL = 'GET_CIC_DETAIL';
export const SET_CIC_DETAIL = 'SET_CIC_DETAIL';
export const UPDATE_PENDING_API = 'UPDATE_PENDING_API';
export const SET_ENV_VARIABLES = 'SET_ENV_VARIABLES';
export const GET_ENV_VARIABLES = 'GET_ENV_VARIABLES';
export const SET_ENV_VARIABLES_FAILURE = 'SET_ENV_VARIABLES_FAILURE';
export const UPDATE_ADD_CIC = 'UPDATE_ADD_CIC';
export const ADD_ITEM_GROUP = 'ADD_ITEM_GROUP';
export const UPDATE_ITEM_GROUP = 'UPDATE_ITEM_GROUP';
export const DELETE_ITEM_GROUP = 'DELETE_ITEM_GROUP';
export const RESET_STORE_ITEMGRP = 'RESET_STORE_ITEMGRP';
export const RESET_ITEMGRP = 'RESET_ITEMGRP';