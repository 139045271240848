/* eslint-disable */
import jwt_decode from 'jwt-decode';
import { regExp } from '../constants/regExp';
import { authBaseURL } from './apiBaseUrl';

const { cookieReplaceRegex, cookieRepValue, cookieMatchStartRegex, cookieMatchEndRegex } = regExp;

export const authProvider = () => {
    let accessToken: any;
    let decodedIdToken: any;

    accessToken = getCookie('ent-abs-auth');
    const idToken = getCookie('ent-abs-itkn');
    const apiToken = accessToken ? accessToken.split('%22')[3] : null;

    try {
        decodedIdToken = typeof idToken === 'string' ? jwt_decode(idToken) : null;
    } catch (e) {
        decodedIdToken = null;
        sessionStorage.clear();
        localStorage.clear();
        window.location.replace(`${authBaseURL}/authenticate`);
    }
    const clearSession = () => {
        sessionStorage.clear();
        localStorage.clear();
        window.location.replace(`${authBaseURL}/authenticate`);
    };
    const logout = () => {
        sessionStorage.clear();
        localStorage.clear();
        window.location.replace(`${authBaseURL}/logout`);
    };
    return {
        accessToken: accessToken,
        apiToken: apiToken,
        idToken: idToken,
        decodedIdToken: decodedIdToken ? decodedIdToken : null,
        name: decodedIdToken ? decodedIdToken.name : null,
        mail: decodedIdToken ? decodedIdToken['preferred_username'] : null,
        userId: decodedIdToken ? decodedIdToken['preferred_username'].split('@')[0] : null,
        division: '',
        store: '',  // commenting logic for release 1 as per feedback
        // division: (decodedIdToken && decodedIdToken.swyDivision) ? (decodedIdToken.swyDivision && parseInt(decodedIdToken.swyDivision, 10)) : '',
        // store: (decodedIdToken && decodedIdToken.swyFacilityCode) ? (decodedIdToken.swyFacilityCode && parseInt(decodedIdToken.swyFacilityCode, 10)) : '',
        clearSession: clearSession,
        logout: logout,
    };
};

function getCookie(name: string) {
    function escape(s: string) {
        return s.replace(cookieReplaceRegex, cookieRepValue);
    }

    var match = document.cookie.match(RegExp(`${cookieMatchStartRegex}${escape(name)}${cookieMatchEndRegex}`));
    return match ? match[1] : null;
}
