import React, { useEffect, useState } from "react";
import InputField from "../../inputField";
import './editItemGrpModal.css'
import Button from "../../button";
import { toast } from "react-toastify";
import { regExp } from "../../../constants/regExp";

interface IGrpModal {
    isModalOpen: boolean,
    hideAddGroupModal: () => void,
    updateItmGroupFn: (data: any) => void,
    itemgrpDta?: any,
    itenGroupDropdown?: any
}

const EditItemGroupModal = ({ isModalOpen, hideAddGroupModal, updateItmGroupFn, itemgrpDta, itenGroupDropdown }: IGrpModal) => {
    let [formData, setFormData] = useState({ itmGrp: '' });
    let { itmGrp } = { ...formData };
    useEffect(() => {
        let addData = {
            ...formData,
            itmGrp: itemgrpDta.value,
        }
        setFormData(addData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemgrpDta]);

    const handleClose = () => {
        hideAddGroupModal();
    }
    const updateItmGroup = () => {
        const itmg = (itenGroupDropdown.itemGroupList).some((item: any) => item.groupName === itmGrp);
        const nameValidation = regExp.restrict.test(itmGrp)
        if(nameValidation){
            toast.error('Item group name format not supported')
        }else{
            if (itmg) {
                toast.error('Item group name already exist')
            }
            else if (itmGrp?.trim()) {
                let editData = {
                    editedGrpName: itmGrp,
                    editedGrpId: itemgrpDta.id
                }
                updateItmGroupFn(editData);
                hideAddGroupModal();
            }
        }
    }
    const handleChange = (e: any) => {
        let target = (e.target as HTMLInputElement);
        let addData = {
            ...formData,
            [target.name]: target.value,
        }
        setFormData(addData)
    }
    return (
        <div
            id="popup-modal"
            className={` ${!isModalOpen && 'hidden'
                } flex flex-col justify-center items-center bg-[rgba(0,0,0,.2)] rounded-lg shadow overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full`}
        >
            <div className="modal-content border-none shadow-lg relative flex flex-col w-[70%] sm:w-[40%] pointer-events-auto bg-white bg-clip-padding outline-none">
                <div className="text-[#00529F] font-bold border-b-[#B1B1B1] border-b-[1.5px] text-center uppercase md:h-[67px] items-center align-middle pt-6 ">
                    <p className="title text-center">UPDATE ITEM GROUP</p>
                </div>
                <div className="flex flex-col justify-center pt-[10px] p-5">
                    <form>
                        <div className='gap-2 md:flex-row'>
                            <InputField
                                label="Item Group"
                                id="itmGrp"
                                testid="itmGrp"
                                styleClass="md:w-5/6 w-full input-field h-[40px]"
                                name="itmGrp"
                                value={itmGrp}
                                maxLength = {25}
                                handleChange={handleChange}
                                labelStyle='text-[17px] py-2'
                                isMandatory
                                inputRegex={regExp.itemGroup}
                                onKeyPress={(e: any) => { e.key === 'Enter' && e.preventDefault(); }}
                            />
                        </div>
                        <div className="flex gap-4 pt-10 justify-center pl-2 pr-2">
                            <Button text="Update" type="contained" data-testid="updateBtn" styleClass="w-[60%] px-[12px] py-[6px]" disabled={itmGrp===''} onClick={updateItmGroup} />
                            <Button text="Cancel" type="contained" data-testid="cancelBtn" styleClass="w-[60%] px-[12px] py-[6px]" onClick={handleClose} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditItemGroupModal
