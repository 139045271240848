import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import HealthCheck from '../healthCheck/healthCheck';
import AdminLayout from '../../pages/admin/adminLayout/adminLayout';

const AppRoutes = (): ReactElement => {
    return (
        <Routes>
            <Route path="/" element={<AdminLayout />} />
            <Route path="/health" element={<HealthCheck />} />
        </Routes>
    );
};

export default AppRoutes;
