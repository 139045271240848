import axios from 'axios';
import { authProvider } from './authProvider';
import store from '../store';
import { UPDATE_PENDING_API } from '../actions/types';

const axiosAuth = axios.create({});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

let isTestEnv = false;
export const setTestEnv = (value: boolean) => {
    isTestEnv = value;
};
// intercept every requests 
axiosAuth.interceptors.request.use(async function (config: any) {
    try {
        if (!isTestEnv) {
            const auth = authProvider();
            const accessToken = auth.accessToken;
            store.dispatch({ type: UPDATE_PENDING_API, value: 1 });
            if (accessToken) {
                config.headers['Content-Type'] = 'application/json';
                config.headers['userId'] = auth.userId;
                config.headers['Access-Control-Allow-Origin'] = '*';
                config.headers['Access-Control-Allow-Credentials'] = true;
                config.headers['Authorization'] = 'Bearer ' + auth.apiToken;
                config.headers['Ocp-Apim-Subscription-Key'] = store?.getState()?.globalDtl?.globalDtl?.env?.apimSubscriptionKey;
                return config;
            } else {
                auth.clearSession();
                source.cancel('Access Token not available');
            }
        } else {
            return config;
        }
    } catch (error) {
        //Handle Error
        return config;
    }
})


// intercept every response
axiosAuth.interceptors.response.use(async function (config: any) {
    store.dispatch({ type: UPDATE_PENDING_API, value: -1 });
    return config;
}, (error: any) => {
    store.dispatch({ type: UPDATE_PENDING_API, value: -1 });
    if (!error.response) {
        if (error.message.match('cancelToken')) {
            throw JSON.stringify({ message: 'Session Expired' });
        }
        throw JSON.stringify({ message: error.message });
    } else {
        throw JSON.stringify(error.response.data);
    }
});
export { axiosAuth }
