import React, { useState } from "react";
import InputField from "../../inputField";
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from "redux";
import './addItemGrpModal.css'
import Button from "../../button";
import { additemGrp } from "../../../actions/admin";
import { toast } from "react-toastify";
import { authProvider } from "../../../utils/authProvider";
import { regExp } from "../../../constants/regExp";
const auth = authProvider();

interface IGrpModal {
    isModalOpen: boolean,
    hideAddGroupModal: () => void,
    division: any,
    store: any,
    divisionDropdown?: any,
    addItmGroup: (data: any) => void,
    itenGroupDropdown: any,

}

export const AddItemGroupModal = ({ isModalOpen, hideAddGroupModal, division, divisionDropdown, store, addItmGroup, itenGroupDropdown }: IGrpModal) => {
    let [formData, setFormData] = useState({ itmGrp: '' })
    let { itmGrp } = { ...formData };

    const handleClose = () => {
        hideAddGroupModal()
    }
    const getStoreId = (auth: any, store: any) => {
        if (auth.store && auth.division) {
          return auth.store;
        } else if (store) {
          return store.map((item: any) => item.value);
        } else {
          return [];
        }
      };

    const addItemGroup = () => {
        const nameValidation = regExp.restrict.test(itmGrp)

        const divItem = !auth.division ? (divisionDropdown?.divisionList).find((item: any) => item.divisionName.trim() === division.toUpperCase()) : '';
        const itmg = (itenGroupDropdown.itemGroupList).some((item: any) => item.groupName === formData.itmGrp);
        if (itmg) {
            toast.error('Item group name already exist')
        } else if (nameValidation) {
            toast.error('Item group name format not supported');
        }
        else if (formData?.itmGrp?.trim() && (auth.division || auth.store || divItem)) {
            addItmGroup({
                divId: auth.division || divItem.divisionNumber,
                storeId: getStoreId(auth, store),
                itemGroupName: formData.itmGrp
            })
            handleClose()
        }
    }
    const handleChange = (e: any) => {
        let target = (e.target as HTMLInputElement);
        let addData = {
            ...formData,
            [target.name]: target.value,
        }
        setFormData(addData)
    }

    return (
        <div
            id="popup-modal"
            className={` ${!isModalOpen && 'hidden'} flex flex-col justify-center items-center bg-[rgba(0,0,0,.2)] rounded-lg shadow overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 sm:inset-0  h-modal h-full `}
        >
            <div className="modal-content border-none shadow-lg relative flex flex-col w-[70%] sm:w-[40%] pointer-events-auto bg-white bg-clip-padding outline-none">
                <div className="text-[#00529F] font-bold border-b-[#B1B1B1] border-b-[1.5px] text-center uppercase md:h-[67px] items-center align-middle pt-6">
                    <p className="title text-center">ADD ITEM GROUP</p>
                </div>
                <div className="flex flex-col justify-center pt-[35px] p-5 space-y-6">
                    <form>
                        <div className='space-y-0'>
                            <div className='  '>
                                <InputField
                                    label="Item Group"
                                    id="itmGrp"
                                    testid="itmGrp"
                                    styleClass="md:w-5/6 w-3/6 h-[37px] bg-[#FFFFFF] pl-4 border-[1px] border-[#B1B1B1] rounded left"
                                    name="itmGrp"
                                    value={itmGrp}
                                    handleChange={handleChange}
                                    labelStyle='text-[17px] py-2'
                                    maxLength={25}
                                    inputRegex={regExp.itemGroup}
                                    isMandatory
                                    onKeyPress={(e: any) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-4 pt-10 justify-center pl-2 pr-2">
                            <Button text="Add" type="contained" data-testid="addBtn" styleClass="w-[60%] px-[12px] py-[6px]" disabled={itmGrp === ''} onClick={addItemGroup} />
                            <Button text="Cancel" type="contained" data-testid="cancel-Btn" styleClass="w-[60%] px-[12px] py-[6px]" onClick={handleClose} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    divisionDropdown: state.adminScreen.divisionDropdown,
    itenGroupDropdown: state.adminScreen.itenGroupDropdown,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    addItmGroup: (data: any) => dispatch(additemGrp(data))
})
const AddGroupModal = connect(mapStateToProps, mapDispatchToProps)(AddItemGroupModal)
export default AddGroupModal
