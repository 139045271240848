import { AnyAction, applyMiddleware, compose, createStore, Dispatch, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './reducers';
import { commonReducer, globalReducerInt } from './reducers/global';
import { rootSaga } from './sagas/index';


export interface IRootReduxState {
    adminScreen: IReduxAdmin,
    globalDtl: globalReducerInt,
}

export interface IReduxGlobal {
    globalDtl: commonReducer,
}

export interface IReduxAdmin {
    divisionDropdown: {},
    storeDropdown: {},
    itenGroupDropdown: {},
    itemCicData: {},
    addSuggestionData: {}
}

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const preloadState = composeEnhancers(applyMiddleware(sagaMiddleware));
const store: Store<IRootReduxState, AnyAction> & {
    dispatch: Dispatch;
} = createStore(rootReducer, preloadState);
export type RootState = ReturnType<typeof rootReducer>;
sagaMiddleware.run(rootSaga);

export default store;
