import React, { useRef } from 'react';

interface IInputFieldProps {
  value?: string | number;
  id?: string;
  label?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  styleClass?: string;
  errMsgWidth?: string;
  isMandatory?: boolean;
  toolTip?: boolean;
  testid?: string;
  helperText?: string;
  error?: boolean;
  errorMessage?: string
  maxLength?: number;
  isCurrencySymbol?: boolean;
  mandatoryData?: any;
  saveState?: boolean;
  labeldisabled?: boolean;
  isCustomCodeGuarantee?: boolean;
  errorFieldName?: string;
  captureError?: any;
  errorList?: Array<any>;
  screenFieldDisable?: boolean;
  badge?: JSX.Element;
  assocText?: string;
  dependentField?: string;
  inputRegex?: RegExp;
  focusField?: boolean;
  labelStyle?: string;
  onKeyPress?: any;
}
function InputField(props: IInputFieldProps) {
  const {
    value,
    id,
    handleChange,
    label,
    disabled,
    name,
    styleClass,
    isMandatory,
    testid,
    helperText,
    error,
    errorMessage,
    maxLength,
    saveState,
    labeldisabled,
    errMsgWidth,
    screenFieldDisable,
    badge,
    inputRegex,
    focusField,
    labelStyle,
    onKeyPress,
  } = props;

  const ref = useRef<HTMLInputElement>(null);

  const handleFocus = (currentRef: any) => {
    currentRef.focus()
  };

  return (
    <div className='items-center gap-3'>
      <label className={`block text-left  font-semibold text-black ${labelStyle}`} data-testid='label'>
        {label}{isMandatory ? <span data-testid='required' className='text-red-600'>*</span> : null}
        {badge ? badge : null}
      </label>
      <input
        className={`form-control ${styleClass} focus:border-primary focus:outline-0  ${disabled ? "shadow-none !bg-[#edeeef]" : ""}
          ${screenFieldDisable ? 'screenFieldDisable' : ''}
            ${labeldisabled ? "shadow-none" : "pl-4"} `}
        id={id}
        value={value}
        name={name}
        disabled={disabled}
        onChange={(e) => {
          if ((handleChange && inputRegex && ((e.target.value).match(RegExp(inputRegex)))) || (handleChange && !inputRegex)) {
            handleChange(e);
          } 
        }}
        onClick={() => {
          if (focusField) handleFocus(ref.current)
        }
        }
        data-testid={testid}
        maxLength={maxLength}
        ref={ref}
        onKeyPress={onKeyPress}
      />
      {helperText ? <span className='pb-3 text-left text-sm text-gray-400 block italic' data-testid='label'>{helperText}</span> : null}
      {(error && !saveState) ? <span className={`pb-3 text-left text-sm text-red-400 block ${errMsgWidth}`} data-testid='label'>{errorMessage}</span> : null}
    </div>
  );
}
export default InputField;
