import { AnyAction } from "redux";
import {
    SET_ENV_VARIABLES,
    UPDATE_PENDING_API,
    SET_ENV_VARIABLES_FAILURE
} from "../actions/types";
import { IReduxGlobal } from "../store";

export interface commonReducer {
    pendingAPICount: number,
    env: any,
}

export interface globalReducerInt {
    globalDtl: commonReducer
}

const commonReducerInit = {
    pendingAPICount: 0,
    env: {},
};


const globalReducer = (state: IReduxGlobal = { globalDtl: commonReducerInit }, action: AnyAction = {type: ''}): IReduxGlobal => {
    switch (action.type) {
        case UPDATE_PENDING_API:
            return {
                ...state,
                globalDtl: {
                    ...state.globalDtl,
                    pendingAPICount: state.globalDtl.pendingAPICount + action.value,
                }
            };
        case SET_ENV_VARIABLES:
            return {
                ...state,
                globalDtl: {
                    ...state.globalDtl,
                    env: action.data,
                }
            };
        case SET_ENV_VARIABLES_FAILURE:
            return {
                ...state,
                globalDtl: {
                    ...state.globalDtl,
                    env: {},
                }
            };
        default:
            return state;
    }
};
export default globalReducer;