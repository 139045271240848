export const responseMsg = {
    addItemGrpSuccess: "Item Group Successfully Added",
    addItemGrpFailure: "Add Item Group Failed",
    deleteCicSuccess: "Record Deleted Successfully",
    deleteCicFailure: "Record Delete Failed",
    updateCicSuccess: "Record Updated Successfully",
    updateCicFailure: "Record Update Failed",
    addCicSuccess: "CIC Successfully Added",
    addCicFailure: "Add CIC Failed",
    deleteItemGrpSuccess: "Item Group Successfully Deleted",
    deletItemGrpFailure: "Delete Item Group Failed",
    updateItemGrpSuccess: "Item Group Successfully Updated",
    updateItemGrpFailure: "Update Item Group Failed",
    fetchCicItemsFailure: "Failed to load CIC Items",
    fetchStoresFailure: "Failed to load Stores",
    fetchDivisionFailure: "Failed to load Division",
    fetchItemGrpFailure: "Failed to load Item Groups",
    fetchCicSuggestionFailure: "Failed to load Item CIC"
}