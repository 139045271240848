import { axiosAuth } from '../utils/axiosInterceptor';
const hostname = window.location.hostname;

const getEnvVariableApi = async (): Promise<any> => {
    return await axiosAuth.get(
        `${hostname.includes('localhost') ? 'http://localhost:7000' : origin
        }/deli/envvariable`
    )
        .then((responseDta) => {
            return { response: responseDta.data };
        })
        .catch((err) => {
            return err;
        })
};

const envVariable = {
    getEnvVariableApi
};

export default envVariable;