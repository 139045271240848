/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Button from '../../button';

interface IConfirmModal {
  title: string;
  message: string;
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export default function ConfirmModal({
  title,
  isModalOpen,
  onClose,
  onSuccess,
  message,
}: IConfirmModal) {
  return (
    <div
      id="popup-modal"
      className={` ${!isModalOpen && 'hidden'
        } flex flex-col justify-center items-center bg-[rgba(0,0,0,.2)] rounded-lg shadow overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full`}
    data-testid="confirm-modal">
      <div className="modal-content border-none shadow-lg relative flex flex-col w-[70%] sm:w-[40%] pointer-events-auto bg-white bg-clip-padding outline-none">
        <div className="flex flex-row content-center justify-between border-b pb-2 p-5">
          <h1 className="w-fit text-base font-bold font-nunito-Regular text-black text-[15px]">
            {title}
          </h1>
        </div>
        <div className="flex flex-col justify-center pt-[10px] p-5 text-[15px]">
          <div className="flex flex-row content-center h-full w-full">
            {message}
          </div>
          <div className="flex gap-4 justify-center pt-10 pl-2 pr-2">
            <Button text="OK" type="contained" testid='conform-button' styleClass="w-[60%] px-[12px] py-[6px]" onClick={() => { onSuccess() }} />
            <Button type="contained" text="Cancel" testid='cancel-button' styleClass="w-[60%] px-[12px] py-[6px]" onClick={() => { onClose() }} />
          </div>
        </div>
      </div>
    </div>
  );
}
