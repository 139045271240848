import React from "react";

export const HealthCheck = () => {
  return (
    <div>
      <strong>App is running</strong>
    </div>
  );
};

export default HealthCheck;
