import React from "react";
import InputField from "../../../components/inputField";
import { regExp } from "../../../constants/regExp";
import { deleteIcon, dragIcon } from "../../../assets/index";
import { checkIfDateIs28DaysOld } from "../../../utils/checkValidDate";
interface TemplateProps {
  item: any;
  itemSelected?: any;
  dragHandleProps?: any;
  deleteItem?: any;
  commonProps?: any;
  isModifyStores?: boolean;
}

interface TemplateState {
  value: number;
}

export default class AdminTemplate extends React.Component<
  TemplateProps,
  TemplateState
> {
  state = {
    value: 0,
  };

  _inc(value: any) {
    this.setState({
      value: parseInt(value, 10) || 0,
    });
  }

  render() {
    const { item, dragHandleProps, commonProps } = this.props;
    return (
      <div className="grid grid-cols-12 m-0 cursor-move" {...dragHandleProps}>
        <div className=" col-span-2 border-[#00000029] border-b-[1px]  text-[#707070] p-[10px] pt-[15px] text-center  text-[16px] ">
          <div className="relative w-max flex flex-row justify-center items-center">
            <img
              src={dragIcon}
              title="Delete"
              width="25px"
              height="25px"
              className="inline pl-[5px] float-left"
              alt="delete"
            />
            <span className="self-end mr-2">{item.cic}</span>
            {item?.itemRecodeReplaceDt &&
              checkIfDateIs28DaysOld(item?.itemRecodeReplaceDt) && (
                <div className="relative flex flex-col items-center group">
                  <div className="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                    <span className="relative w-32 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
                      <p className="m-1">{item?.oldCic}</p>
                      <p className="m-1">
                        {item?.itemRecodeReplaceMsg === "Item Rplce"
                          ? "Item Replaced on"
                          : "Item Recoded on"}
                      </p>
                      <p className="m-1">{item?.itemRecodeReplaceDt}</p>
                    </span>
                    <div className="w-3 h-3 -mt-2 rotate-45 bg-byl2 px-1.5ck bg-black"></div>
                  </div>
                  <svg
                    viewBox="0 0 1024 1024"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                    className="text-[#00529F]"
                  >
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 010-96 48.01 48.01 0 010 96z" />
                  </svg>
                </div>
              )}
          </div>
        </div>
        <div className="col-span-4 border-[#00000029] border-b-[1px]  text-[#707070] p-[10px] pt-[15px] text-[16px]">
          {item.description}
        </div>
        <div className="col-span-1 border-[#00000029] border-b-[1px]  text-[#707070] p-[10px] pt-[15px] text-center text-[16px]">
          {item.casePack}
        </div>
        <div className="col-span-2 border-[#00000029] border-b-[1px]  text-[#707070] p-[10px] pt-[15px] text-center text-[16px]">
          {item.packSize}
        </div>
        <div className="col-span-2 border-[#00000029] border-b-[1px]   text-[#00529F] p-[10px] text-center text-[16px]">
          <InputField
            disabled={item.isZinv === "false" || commonProps.isModifyStores}
            value={this.state.value || item.presentationStock}
            maxLength={4}
            labeldisabled
            inputRegex={regExp.prstnStockRegex}
            styleClass="w-[62px] h-[38px] rounded border-[#B1B1B1] text-center  font-semibold"
            focusField
            handleChange={(e) => {
              this._inc(e.target.value);
              item.presentationStock = e.target.value;
              const cic = item.cic;
              // item.description = e.target.value
              const modifiedData = commonProps.dataList.map((itemDta: any) => {
                if (itemDta.productGroup === commonProps.productGroup) {
                  itemDta.data.forEach((ele: any) => {
                    if (ele.cic === cic) {
                      ele.presentationStock = e.target.value
                        ? parseInt(e.target.value, 10)
                        : 0;
                    }
                  });
                }
                return itemDta;
              });
              commonProps.updateData(modifiedData);
            }}
            testid="editCic"
          />
        </div>
       {!commonProps.isModifyStores && <div className="col-span-1 border-[#00000029] border-b-[1px] py-[10px] fill-[#00529F] font-bold p-[10px] text-center text-[20px]">
          <img
            src={deleteIcon}
            title="Delete"
            className="w-[30px] h-[30px] cursor-pointer"
            alt="delete"
            onClick={() => {
              commonProps.deleteItem(item);
            }}
            data-testid="deleteCic"
          />
          {/* <button onClick={() => { commonProps.deleteItem(item) }} data-testid='deleteCic'>DELETE</button> */}
        </div>}
      </div>
    );
  }
}
