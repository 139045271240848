
export default function Loader() {
    return (
        <div className="flex px-5 h-full w-full items-center justify-center bg-white-rgba fixed z-[60]"
        data-testid='loader-section'>
            <div className="h-14 align-middle items-center justify-center absolute top-[50%] left-[50%]">
                <svg
                    width="25"
                    viewBox="-2 -2 42 42"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#2d3748"
                    className="w-full h-full"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="4">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        </div >

    );
}