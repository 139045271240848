import  { Dispatch  } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { deleteCic, deleteitemGrp, getCicData, getDivisions, getItemData, getItemGrp, getStores, resetItemGrp, resetStoreItemGrp, updateAddCic, updateCicItem, updateitemGrp, } from '../../../actions/admin';
import { AdminLayoutComp } from './adminLayoutComp';


const mapStateToProps = (state: any) => ({
  divisionDropdown: state.adminScreen.divisionDropdown,
  storeDropdown: state.adminScreen.storeDropdown,
  itenGroupDropdown: state.adminScreen.itenGroupDropdown,
  itemCicData: state.adminScreen.itemCicData,
  addSuggestionData: state.adminScreen.addSuggestionData
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  getDivision: () => dispatch(getDivisions()),
  getStores: (data: any) => dispatch(getStores(data)),
  getItemGrp: (data: any) => dispatch(getItemGrp(data)),
  getItemData: (data: any) => dispatch(getItemData(data)),
  deleteCic: (data: any) => dispatch(deleteCic(data)),
  updateCicdata: (data: any) => dispatch(updateCicItem(data)),
  getCicData: (data: any) => dispatch(getCicData(data)),
  updateAddCic: (data: any) => dispatch(updateAddCic(data)),
  deleteitemGrp: (data: any) => dispatch(deleteitemGrp(data)),
  updateitemGrp: (data: any) => dispatch(updateitemGrp(data)),
  resetStoreItemGrp: () => dispatch(resetStoreItemGrp()),
  resetItemGrp: () => dispatch(resetItemGrp())
});

const AdminLayout = connect(mapStateToProps, mapDispatchToProps)(AdminLayoutComp);

export default AdminLayout;