import {
    GET_ENV_VARIABLES,
    SET_ENV_VARIABLES,
    SET_ENV_VARIABLES_FAILURE,
} from "./types";

export const getEnvVariableAction = () => ({
    type: GET_ENV_VARIABLES
})

export const setEnvVariable = (data: any) => ({
    type: SET_ENV_VARIABLES,
    data
});

export const setEnvVariableFailure = () => ({
    type: SET_ENV_VARIABLES_FAILURE,
    data: {}
})
